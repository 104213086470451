import { AppProps } from 'next/app'
import React, { useEffect, useRef } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import '../styles/global.css'
import '../styles/base.css'
import '@fontsource/sarabun/300.css'
import '@fontsource/sarabun/400.css'
import '@fontsource/sarabun/500.css'
import '@fontsource/sarabun/600.css'
import '@fontsource/sarabun/700.css'
import '@fontsource/lora/400.css'
import '@fontsource/lora/400-italic.css'
import '@fontsource/lora/500.css'
import '@fontsource/lora/600.css'
import '@fontsource/lora/600-italic.css'
import '@fontsource/lora/700.css'
import {
  APP_ENV,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  GIT_HASH,
} from '~/config/env'
import { useCookiePrefCookie } from '@package/ds/cookie-banner/useCookiePrefCookie'
import { Toaster } from '@package/ds/toast'

const App = ({ Component, pageProps }: AppProps) => {
  const [cookie] = useCookiePrefCookie()

  const isDDInitialized = useRef(false)

  useEffect(() => {
    if (!isDDInitialized.current && cookie === 'accepted') {
      datadogRum.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'www-ui',
        env: APP_ENV,
        version: GIT_HASH,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [
          /https:\/\/.*\.?custodiabank\.com/,
          /https:\/\/.*\.?custodiabank-stage\.com/,
          /http:\/\/localhost:.*/,
        ],
      })

      isDDInitialized.current = true
    }
  }, [cookie])

  return (
    <>
      <Toaster />
      <Component {...pageProps} />
    </>
  )
}

export default App
