import { useEffect, useState } from 'react'
import useCookie from '@package/ds/cookie-banner/use-cookie'

const cookiePrefCookieName = 'cookie_pref'
const defaultMaxAge = 60 * 60 * 24 * 365 // 1 year timeout

export const useCookiePrefCookie = (): [
  '' | 'accepted' | 'rejected',
  (cookiesPolicyStatus: 'rejected' | 'accepted') => void,
  boolean,
] => {
  const [hydrated, setHydrated] = useState(false)
  useEffect(() => {
    setHydrated(true)
  }, [])
  const [cookie, setCookie] = useCookie(cookiePrefCookieName)
  const setCookieWrapper = (cookiesPolicyStatus: 'rejected' | 'accepted') =>
    setCookie({
      name: cookiePrefCookieName,
      value: cookiesPolicyStatus,
      maxAge: defaultMaxAge,
    })
  const cookieValue =
    cookie.indexOf('=') !== -1 ? cookie.substring(cookie.indexOf('=') + 1) : ''
  return [
    cookieValue as '' | 'accepted' | 'rejected',
    setCookieWrapper,
    !cookie && hydrated,
  ]
}
