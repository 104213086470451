const APP_ENV = process.env.APP_ENV || 'development'
const DATADOG_APPLICATION_ID = process.env.DATADOG_WWW_APPLICATION_ID
const DATADOG_CLIENT_TOKEN = process.env.DATADOG_WWW_CLIENT_TOKEN
const GIT_HASH = process.env.GITHUB_SHA ?? 'no-hash'
const WWW_BASE_URL = process.env.WWW_BASE_URL ?? 'https://custodiabank.com'

export {
  APP_ENV,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  GIT_HASH,
  WWW_BASE_URL,
}
