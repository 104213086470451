import { useEffect, useState } from 'react'
import { CookieType } from './types'

/**
 * Looks up a cookie by its `name`
 *
 * @param {string} name - the name property set on the cookie
 * @returns {string} - the full string representation of the cookie
 */
export function getCookie(name: string): string {
  // typeof window !== 'undefined' is for our Next.js www app (local development)
  // where we must check if we are in a browser. Otherwise will always be true
  if (typeof window !== 'undefined' && document?.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (const cookie of cookies) {
      const potentialKey = cookie.trim().substring(0, name.length + 1)
      if (potentialKey === `${name}=`) {
        return decodeURIComponent(cookie)
      }
    }
  }
  return ''
}

export function parseCookie(cookie: string) {
  const [name, value] = cookie.split('=')
  return {
    name,
    value,
  }
}

export function getCookieValue(name: string): string {
  const cookie = getCookie(name)
  if (cookie) {
    return parseCookie(cookie).value
  }
  return ''
}

/**
 * A utility function to set a cookie in the browser using `document.cookie`.
 *
 * @param {CookieType} details
 */
export function setCookie({
  name,
  value,
  expiration = null,
  maxAge = null,
  sameSite = 'Lax',
}: CookieType): void {
  const currentHostname = location.hostname
  const domain = currentHostname.includes('localhost')
    ? 'localhost'
    : currentHostname.split('.').slice(-2).join('.')

  if (!domain) {
    throw new Error('unable to retrieve document.domain')
  }

  const isSecure = location.protocol === 'https:' ? '; secure' : ''

  let expires = ''
  if (expiration == null) {
    expires = ''
  } else if (typeof expiration === 'string') {
    expires = `; expires=${expiration}`
  } else {
    expires = `; expires=${expiration.toUTCString()}`
  }

  const timeout = typeof maxAge == 'number' ? `; max-age=${maxAge}` : ''
  const cookie = `${name}=${value}; path=/; domain=${domain}${expires}${timeout}${isSecure}; samesite=${sameSite}`
  try {
    document.cookie = cookie
  } catch (e) {
    // cookies are disabled
  }
}

/**
 * A React hook for interfacing with browser cookies.
 * Besides providing basic CRUD operations, this hook also allows a component to react to changes in a cookie.
 *
 * @param {string} name
 * - the name field for the cookie you want to observe or use, is '' if cookie does not exist
 * @param {number} [pollingRate=1000] - milliseconds - the rate that this hook checks for changes to the specified cookie
 * @returns {CookieState} [cookie, setCookie] - acts like [state, setState] returned from useState.
 */
export default function useCookie(
  name: string,
  pollingRate = 1000,
): [string, (newCookie: CookieType) => void] {
  const [value, setValue] = useState(() => getCookie(name))

  useEffect(() => {
    // setInterval to check if cookie changes out from under us
    const interval = setInterval(() => {
      const val = getCookie(name)
      setValue(val)
    }, pollingRate)
    return () => clearInterval(interval)
  }, [name, pollingRate])

  function updateCookie(cookie: CookieType) {
    setCookie(cookie)
    setValue(value)
  }

  return [value, updateCookie]
}
