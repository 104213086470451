import React from 'react'
import toast, { Toast, Toaster as HotToastToaster } from 'react-hot-toast'
import * as S from './styles'
import { Severities } from './types'

type ToasterContainerStyle = {
  left?: number
  right?: number
  top?: number
  bottom?: number
}

export const Toaster = ({
  containerStyle = {},
}: {
  containerStyle?: ToasterContainerStyle
}) => (
  <HotToastToaster
    containerStyle={{
      bottom: 90,
      ...containerStyle,
    }}
  />
)

const getToastDuration = () => (process.env.APP_ENV == 'TEST' ? 1200 : 5000)

export const createToast = (
  message: string,
  severity: Severities = 'info',
): string => {
  const toastId = toast.custom(
    (t) => <InternalToast t={t} message={message} severity={severity} />,
    {
      position: 'bottom-center',
      duration: getToastDuration(),
    },
  )
  // Manually setTimeout to dismiss toast b/c there seems to be a bug inside
  // of react-hot-toast where sometimes the toast doesn't get auto dismissed.
  // In the absence of understanding why this is happening I am manually doing
  // a dismiss here. It is harmless if the library itself beats us to calling
  // dismiss but ensures dismiss is at least called once.
  setTimeout(() => toast.dismiss(toastId), getToastDuration())
  return toastId
}

export const InternalToast: React.FC<{
  t: Toast
  severity: Severities
  message: string
}> = ({ t, severity, message }) => (
  <S.ToastContainer
    data-testid="toast-container"
    className={t.visible ? 'animate-enter' : 'animate-leave'}
    severity={severity}
  >
    <S.ToastText>{message}</S.ToastText>
  </S.ToastContainer>
)
