import styled from 'styled-components'
import tw from 'twin.macro'
import { Severities } from './types'

export const ToastContainer = styled.div<{ severity: Severities }>`
  ${tw`text-black shadow-lg rounded-lg pointer-events-auto flex py-4 px-6 justify-between`}
  ${({ severity }) => {
    switch (severity) {
      case 'success':
        return tw`bg-emerald-500`
      case 'info':
        return tw`bg-yellow-200`
      case 'error':
        return tw`bg-red-500`
    }
  }}
`

export const ToastText = styled.p`
  ${tw`mx-auto`}
`
